<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Testimonios - Listar</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
         
                <b-col sm="12" md="8">
                </b-col>

                <b-col sm="12" md="3">
                  <b-form-group label=".">
                    <b-input-group>
                    <b-form-input @keyup.enter="ListTestimonial" v-model="search" class="form-control"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="ListTestimonial"><b-icon icon="search"></b-icon></b-button>
                    </b-input-group-append>
                  </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col sm="6" md="1">
                  <b-form-group label=".">
                    <b-link  v-if="Permission('TestimonialAdd')" class="btn form-control btn-primary"  :to="{ path: '/testimonio/nuevo' }" append ><i class="fas fa-file"></i></b-link>
                  </b-form-group>
                </b-col>
            </b-row>

            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="30%" class="text-center">Nombre</th>
                    <th width="25%" class="text-center">Email</th>
                    <th width="10%" class="text-center">Pais</th>
                    <th width="15%" class="text-center">Testimonio</th>
                    <th width="8%" class="text-center">Estado</th>
                    <th width="8%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-left"> {{ item.name  }}</td>
                    <td class="text-left"> {{ item.email  }}</td>
                    <td class="text-left"> {{ item.country  }}</td>
                    <td class="text-left"> {{ item.testimony.length > 80 ? item.testimony.substr(0,80) +"..." :item.testimony   }}</td>
                    
                    <td class="text-center">
                        <b-button @click="State(item.id_testimonial)" type="button" class="text-center" :variant="item.state == 1 ? 'success':'danger'">
                        <span v-if="item.state == 1">Activo</span>
                        <span v-if="item.state == 0">Inactivo</span>
                      </b-button>
                    </td>
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item v-if="Permission('TestimonialEdit')" @click="EditTestimonial(item.id_testimonial)" >Editar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('TestimonialView')" @click="ViewTestimonial(item.id_testimonial)" >Ver</b-dropdown-item>
                        <b-dropdown-item  v-if="Permission('TestimonialDelete')" @click="ConfirmDeleteTestimonial(item.id_testimonial)" >Eliminar</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage" v-on:input="ListTestimonial" :total-rows="rows" :per-page="perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
export default {
  name: "UsuarioList",
  components:{
    vSelect,
  },
  data() {
    return {
      module:'Testimonial',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      data_table: [],
      search: "",
    };
  },
  mounted() {
    this.ListTestimonial();
  },
  methods: {
    ListTestimonial,
    EditTestimonial,
    ViewTestimonial,
    ConfirmDeleteTestimonial,
    DeleteTestimonial,
    Permission,
    State,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ListTestimonial() {

  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url = this.url_base + "testimonial/list/" + search + "?page=" + this.currentPage;

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// Editar usuario
function EditTestimonial(id_testimonial) {
  this.$router.push({
    name: "TestimonialEdit",
    params: { id_testimonial: je.encrypt(id_testimonial) },
  });
}

// Ver Usuario
function ViewTestimonial(id_testimonial) {
  this.$router.push({
    name: "TestimonialView",
    params: { id_testimonial: je.encrypt(id_testimonial) },
  });
}

// Confirmar eliminar
function ConfirmDeleteTestimonial(id_testimonial) {
  Swal.fire({
    title: "Esta seguro de eliminar el testimonio?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteTestimonial(id_testimonial);
    }
  });
}

// eliminar usuario
function DeleteTestimonial(id_testimonial) {
  let me = this;
  let url = this.url_base + "testimonial/delete/" + id_testimonial;
  axios({
    method: "delete",
    url: url,
    headers: {token: this.token, module: this.module,role: 4,},
  })
    .then(function (response) {
       if (response.data.status == 200) {
        const index = me.data_table.map(item => item.id_testimonial).indexOf(response.data.result.id_testimonial);
        me.data_table.splice(index, 1);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function State(id_testimonial) {
  let me = this;
  let url = this.url_base + "testimonial/state/" + id_testimonial;
  axios({
    method: "get",
    url: url,
    headers: {token: this.token, module: this.module,role: 3},
  })
    .then(function (response) {
       if (response.data.status == 200) {
        const index = me.data_table.map(item => item.id_testimonial).indexOf(response.data.result.id_testimonial);
        me.data_table[index].state = response.data.result.state;
        // Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
